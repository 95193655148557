import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { Teacher } from "../../types/teacher"
import { ScriptureReference } from "../../features/scriptures/mappers/scripture-reference-mapper"
import { FavoriteContentType } from "../../types/favorite"
import { BreadcrumbResource, Breadcrumbs } from "../breadcrumbs/breadcrumbs"
import { IconLabel } from "../icon-label/icon-label"
import { IconVariant } from "../icon/icon-types"
import { ShareableVerse } from "../shareable-verse/shareable-verse"
import { ShareAndLikeButton } from "../share-and-like-button/share-and-like-button"
import { PageHeader, HeaderTitle } from "../generic-page-header"
import { HeaderTeacher } from "./header-teacher"
import styles from "./details-page-header.module.scss"

export interface DetailsPageHeaderProps {
  topics?: BreadcrumbResource[]
  title: string
  teachers?: Array<Teacher>
  childrenClassName?: string
  date?: string
  verseReference?: ScriptureReference
  share?: boolean
  id?: string
  contentType?: FavoriteContentType
  iconVariant?: IconVariant
}

export const DetailsPageHeader: FunctionComponent<DetailsPageHeaderProps> = ({
  children,
  childrenClassName,
  teachers,
  title,
  topics,
  date,
  verseReference,
  share,
  id,
  contentType,
  iconVariant = "12-book"
}) => {
  const hasAuthor = teachers?.length ? true : false

  return (
    <PageHeader
      className={classNames(
        styles.header,
        Boolean(children) && styles.withChildren
      )}
    >
      <div className={styles.content}>
        {date && (
          <IconLabel
            className={classNames(styles.date, "esv-crossref-ignore")}
            variant={iconVariant}
          >
            {date}
          </IconLabel>
        )}
        {Boolean(topics?.length) && (
          <Breadcrumbs
            items={topics as BreadcrumbResource[]}
            size="sm"
            className={styles.breadcrumbs}
          />
        )}
        <HeaderTitle title={title} className={styles.headerTitle} />
        {(teachers || share || verseReference) && (
          <div className={styles.teacherWrapper}>
            {teachers && <HeaderTeacher teachers={teachers} />}
            {share && !verseReference && (
              <ShareAndLikeButton
                className={styles.icons}
                id={id}
                contentType={contentType}
              />
            )}
            {verseReference && (
              <ShareableVerse
                hasAuthor={hasAuthor}
                verseReference={verseReference}
                id={id}
              />
            )}
          </div>
        )}
      </div>
      {children && (
        <div
          className={classNames(styles.additionalContent, childrenClassName)}
        >
          {children}
        </div>
      )}
    </PageHeader>
  )
}
