import React, {
  FunctionComponent,
  // ReactNode,
  Children,
  isValidElement,
  cloneElement
} from "react"
import classNames from "classnames"
import styles from "./sidebar-track.module.scss"

export interface SidebarTrackProps {
  className?: string
  bufferClassName?: string
  stickyContainerClassName?: string
  // children: ReactNode | ((stickyClassName: string) => ReactNode)
  visualization?: boolean
}

export const SidebarTrack: FunctionComponent<SidebarTrackProps> = ({
  className,
  bufferClassName,
  stickyContainerClassName,
  children: childrenProp,
  visualization
}) => {
  const children = /*typeof childrenProp === "function"
      ? childrenProp(styles.sticky)
      : */ Children.map(childrenProp, (child) =>
    isValidElement(child)
      ? cloneElement(child, {
          className: classNames(styles.sticky, child.props.className)
        })
      : null
  )

  const trackFullClassName = classNames(styles.sidebarTrack, className, {
    [styles.visualization]: visualization
  })
  const bufferFullClassName = classNames(styles.buffer, bufferClassName)
  const stickyContainerFullClassName = classNames(
    styles.stickyContainer,
    stickyContainerClassName
  )

  return (
    <div className={trackFullClassName}>
      <div className={bufferFullClassName} />
      <div className={stickyContainerFullClassName}>{children}</div>
      <div className={bufferFullClassName} />
    </div>
  )
}
