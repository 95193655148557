import { getSrc } from "gatsby-plugin-image"
import { PostPageQuery, ContentfulTeacher } from "../../../../graphql/types"
import { PostDetails } from "../post-types"
import { truncate } from "../../../core/utils"
import { mapTeachers } from "../../../core/mappers/map-teachers"
import { mapMetadata } from "../../../core/mappers/map-metadata"
import { mapNestedTopics } from "../../../core/mappers/map-nested-topics"

const pullquoteMarker = "[pullquote]"
const pullquoteParagraph = `<p>${pullquoteMarker}</p>`

export const mapSourcePostToView = (query: PostPageQuery): PostDetails => {
  const details = query.contentfulPost!

  const teachers = mapTeachers(details.teachers as Partial<ContentfulTeacher>[])
  const topics = mapNestedTopics(details.primaryTopic)

  const extendedCopy = details.extendedCopy
    ?.sort((a: any, b: any) => {
      return a.order - b.order
    })
    ?.map(({ bodyCopy }: any) => {
      const html = bodyCopy?.childMarkdownRemark?.html || ""

      return html
    })
    ?.join("")

  const timeToRead = details.extendedCopy
    ? details.extendedCopy.reduce((acc, cur) => {
        const time = cur?.bodyCopy?.childMarkdownRemark?.timeToRead || 0

        return time + acc
      }, 0)
    : details.bodyCopy?.childMarkdownRemark?.timeToRead || 0

  let body = details.bodyCopy?.childMarkdownRemark?.html || ""
  const pullQuote = details.pullQuote?.childMarkdownRemark
    ? details.pullQuote?.childMarkdownRemark?.html
    : null

  if (pullQuote) {
    body = body.replace(
      pullquoteParagraph,
      `<div class="pullquote">${pullQuote}</div>`
    )
  }

  let description = details.bodyCopy?.childMarkdownRemark?.plainText

  if (description && details.pullQuote) {
    description = description.replace(pullquoteMarker, " ")
  }

  const title = details.name || ""
  const metadata = mapMetadata(
    details.metadata,
    {
      title,
      description: description && truncate(description),
      image: getSrc(details.headerImage?.metadataImage)
    },
    {
      title: details.pageTitle
    }
  )

  return {
    id: details.id || "",
    contentfulId: details.contentful_id || "",
    title,
    body,
    extendedCopy,
    timeToRead,
    image: details.headerImage?.gatsbyImageData,
    topics,
    teachers,
    date: details.date,
    metadata
  }
}
