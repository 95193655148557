import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { IconVariant } from "../icon/icon-types"
import { Icon } from "../icon/icon"
import styles from "./icon-label.module.scss"

export interface IconLabelProps {
  variant: IconVariant
  className?: string
}

export const IconLabel: FunctionComponent<IconLabelProps> = ({
  variant,
  children,
  className
}) => (
  <div
    className={classNames(styles.iconLabel, className, "esv-crossref-ignore")}
  >
    <Icon variant={variant} />
    {children}
  </div>
)
