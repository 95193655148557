import React, { Fragment, FunctionComponent } from "react"
import { Teacher } from "../../types/teacher"
import { Avatar } from "../avatar/avatar"
import { Link } from "../link/link"
import { PathPrefix } from "../../core/constants"
import styles from "./details-page-header.module.scss"
import { AvatarList, AvatarListItemProps } from "@components/avatar/avatar-list"

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface QuestionsAnsweredTeacherItems extends AvatarListItemProps {}

export interface HeaderTeacherProps {
  teachers: Teacher[]
}

export const HeaderTeacher: FunctionComponent<HeaderTeacherProps> = ({
  teachers
}) => {
  const singularAvatarProps = {
    image: teachers[0]?.image,
    name: teachers[0]?.name
  }

  const pluralAvatarItems = teachers?.map<QuestionsAnsweredTeacherItems>(
    (teacherItem) => ({
      name: teacherItem?.name,
      slug: teacherItem?.slug,
      link: teacherItem?.link,
      image: teacherItem?.image
    })
  )

  const isSingularTeacher = teachers && teachers?.length === 1
  const isPluralTeacher = teachers && teachers?.length > 1

  const singleTeacherLink = `${PathPrefix.Teachers}/${teachers[0]?.slug || ""}`

  return (
    <Fragment>
      {isSingularTeacher && (
        <Link to={singleTeacherLink} className={styles.teacher}>
          {teachers[0]?.image && (
            <Avatar
              image={teachers[0]?.image}
              className={styles.avatar}
              size="sm"
              showName
            />
          )}
          <span className={styles.name}>{teachers[0]?.name}</span>
        </Link>
      )}
      {isPluralTeacher && (
        <AvatarList
          avatars={pluralAvatarItems as QuestionsAnsweredTeacherItems[]}
          size="sm"
          showNames
        />
      )}
    </Fragment>
  )
}
