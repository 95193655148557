import React, { FunctionComponent } from "react"
import { FormattedMessage } from "react-intl"
import { stripAttributesFromTag, wrapElementInTag } from "../../posts-helpers"
import styles from "./post-view.module.scss"
import { SidebarTrack } from "@components/sidebar-track/sidebar-track"
import { GlobalPromotionCTA } from "@features/global-promotions/components/global-promotion-cta"
import { Markdown } from "@components/markdown/markdown"
import { useGlobalPromotion } from "@features/global-promotions/hooks/use-global-promotion"

export interface PostViewProps {
  body: string
  timeToRead: number
}

export const PostView: FunctionComponent<PostViewProps> = ({
  body,
  timeToRead
}) => {
  const hasGlobalPromotion = Boolean(useGlobalPromotion())

  const stripIframeWidthAndHeight = (htmlString: string): string =>
    stripAttributesFromTag({
      htmlString,
      tag: "iframe",
      targetAttributes: "width height"
    })

  return (
    <section className={styles.postView}>
      <div className={styles.content}>
        <span className={styles.timeToRead}>
          <FormattedMessage
            id="general-reading-time"
            values={{ time: timeToRead }}
          />
        </span>
        <Markdown
          content={wrapElementInTag({
            htmlString: stripIframeWidthAndHeight(body),
            selector: "iframe",
            wrapper: '<article data="iframe-container"></article>'
          })}
          className={styles.body}
        />
      </div>
      {hasGlobalPromotion && (
        <SidebarTrack>
          <GlobalPromotionCTA />
        </SidebarTrack>
      )}
    </section>
  )
}
