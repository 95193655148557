import React, { FunctionComponent, Fragment } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { DetailsPageHeader } from "../../../../components/details-page-header/details-page-header"
import { PostDetails } from "../../post-types"
import { PostView } from "../post-view/post-view"
import styles from "./post-details-page.module.scss"
import { GlobalPromotionCTA } from "@features/global-promotions/components/global-promotion-cta"
import { AttributionWrapper } from "@components/attribution/attribution"
import { useGlobalPromotion } from "@features/global-promotions/hooks/use-global-promotion"

export interface PostDetailsPageProps {
  post: PostDetails
}

export const PostDetailsPage: FunctionComponent<PostDetailsPageProps> = ({
  post
}) => {
  const hasGlobalPromotion = Boolean(useGlobalPromotion())
  const postImage = post.image && getImage(post.image)

  return (
    <Fragment>
      <DetailsPageHeader
        topics={post.topics}
        title={post.title}
        date={post.date}
        teachers={post.teachers}
        share
        iconVariant="12-article"
        id={post.contentfulId}
        contentType="post"
      >
        {postImage && (
          <div className={styles.image}>
            <GatsbyImage image={postImage} alt="" loading="eager" />
          </div>
        )}
      </DetailsPageHeader>
      <PostView
        body={post.extendedCopy || post.body}
        timeToRead={post.timeToRead}
      />
      {hasGlobalPromotion && (
        <AttributionWrapper className={styles.attributions}>
          <GlobalPromotionCTA className={styles.globalPromoMobile} />
        </AttributionWrapper>
      )}
    </Fragment>
  )
}
