import React, { FunctionComponent, Fragment } from "react"
import { useIntl } from "react-intl"
import { DevotionalDetails } from "../../devotional-types"
import { DetailsPageHeader } from "../../../../components/details-page-header/details-page-header"
import { FurtherStudy } from "../further-study/further-study"
import {
  ScriptureReference,
  formatScriptureReference,
  getBookLocaliser
} from "../../../scriptures/mappers/scripture-reference-mapper"
import styles from "./devotional-view.module.scss"
import { Markdown } from "@components/markdown/markdown"

export interface ScriptureReferenceLabelProps {
  scriptureReference: ScriptureReference
}

export const ScriptureReferenceLabel: FunctionComponent<
  ScriptureReferenceLabelProps
> = ({ scriptureReference }) => {
  const { formatMessage } = useIntl()
  const getLocalisedBook = getBookLocaliser(formatMessage)

  return <>{formatScriptureReference(scriptureReference, getLocalisedBook)}</>
}

export interface DevotionalViewProps {
  contentfulId?: string
  devotional: DevotionalDetails
}

export const DevotionalView: FunctionComponent<DevotionalViewProps> = ({
  contentfulId,
  devotional
}) => (
  <Fragment>
    <DetailsPageHeader
      childrenClassName={styles.pageHeaderVerse}
      topics={devotional.topics}
      title={devotional.title}
      date={devotional.formattedDate}
      verseReference={devotional.scriptureVerse || undefined}
      teachers={devotional.teachers}
      share
      id={contentfulId}
      contentType="devotional"
    >
      {Boolean(devotional.scriptureVerse && devotional.scriptureText) && (
        <Fragment>
          <div className={styles.headerScriptureVerse}>
            {devotional.scriptureVerse && (
              <ScriptureReferenceLabel
                scriptureReference={devotional.scriptureVerse}
              />
            )}
          </div>
          <Markdown
            content={devotional.scriptureText}
            className={styles.headerScriptureText}
          />
        </Fragment>
      )}
    </DetailsPageHeader>
    <div className={styles.body}>
      <div className={styles.bodyContent}>
        <section dangerouslySetInnerHTML={{ __html: devotional.bodyCopy }} />
        {devotional.coramDeo && (
          <section>
            <div className={styles.coramDeo}>
              <h2>Coram Deo</h2>
              <Markdown
                content={devotional.coramDeo}
                className={styles.synopsis}
              />
            </div>
          </section>
        )}
      </div>
    </div>
    {devotional.furtherStudy.length > 0 && (
      <FurtherStudy scriptures={devotional.furtherStudy} />
    )}
  </Fragment>
)
