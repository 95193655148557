import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { PathPrefix } from "../../core/constants"
import { ScriptureReference } from "../../features/scriptures/mappers/scripture-reference-mapper"
import { Link } from "../link/link"
import { ShareAndLikeButton } from "../share-and-like-button/share-and-like-button"
import { ScriptureReferenceLabel } from "../../features/devotionals/components/devotional-view/devotional-view"
import styles from "./shareable-verse.module.scss"

export interface ShareableVerseProps {
  className?: string
  id?: string
  verseReference: ScriptureReference
  hasAuthor?: boolean
}

export const ShareableVerse: FunctionComponent<ShareableVerseProps> = ({
  className,
  id,
  verseReference,
  hasAuthor
}) => {
  const verseLink = `${PathPrefix.Scriptures}/${verseReference.slug}`
  const authorStyle = hasAuthor ? styles.shareableVerse : styles.noAuthor

  return (
    <>
      <div className={classNames(authorStyle, className)}>
        <Link to={verseLink}>
          <ScriptureReferenceLabel scriptureReference={verseReference} />
        </Link>
      </div>
      <div className={styles.flexRowsBreak}></div>
      <div className={classNames(styles.shareButtonWrapper, className)}>
        <ShareAndLikeButton
          className={styles.icons}
          id={id}
          contentType="devotional"
        />
      </div>
    </>
  )
}
