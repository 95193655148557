import React, { FunctionComponent } from "react"
import classnames from "classnames"
import styles from "./generic-page-header.module.scss"

export interface HeaderTitleProps {
  title: string
  className?: string
}

export const HeaderTitle: FunctionComponent<HeaderTitleProps> = ({
  title,
  className
}) => <h1 className={classnames(styles.title, className)}>{title}</h1>
