import React, { FunctionComponent } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import {
  ScriptureReference,
  formatScriptureReference,
  getBookLocaliser
} from "../../../scriptures/mappers/scripture-reference-mapper"
import styles from "./further-study.module.scss"

export interface FurtherStudyProps {
  scriptures: ScriptureReference[]
}

export const FurtherStudy: FunctionComponent<FurtherStudyProps> = ({
  scriptures
}) => {
  const { formatMessage } = useIntl()
  const getLocalisedBook = getBookLocaliser(formatMessage)

  return (
    <section className={styles.furtherStudy} data-testid="furtherStudy">
      <h3>
        <FormattedMessage id="devotional-further-study-label" />
      </h3>
      <div className={styles.list}>
        {scriptures.map((scripture) => (
          <a
            href={`https://www.esv.org/${formatScriptureReference(
              scripture,
              getLocalisedBook
            )}`}
            key={scripture.slug}
            className={styles.item}
            target="_blank"
            rel="noreferrer"
          >
            <span>{formatScriptureReference(scripture, getLocalisedBook)}</span>
          </a>
        ))}
      </div>
    </section>
  )
}
