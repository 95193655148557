import React, { FunctionComponent } from "react"
import classnames from "classnames"
import styles from "./generic-page-header.module.scss"

export interface PageHeaderProps {
  className?: string
}

export const PageHeader: FunctionComponent<PageHeaderProps> = ({
  children,
  className
}) => (
  <header className={classnames(styles.header, className)}>{children}</header>
)
