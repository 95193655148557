import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { PostPageQuery } from "../../../graphql/types"
import { PageProps } from "../../types/page"
import { mapSourcePostToView } from "../../features/posts/mappers/post-mapper"
import { PostDetailsPage } from "../../features/posts/components/post-details-page/post-details-page"
import { PageBreadcrumbs } from "../../core/breadcrumbs"
import { Page } from "@components/page/page"

export const PostTemplate: FunctionComponent<PageProps<PostPageQuery>> = ({
  data,
  ...props
}) => {
  const post = mapSourcePostToView(data!)
  const postData = data?.contentfulPost
  const { metadata } = postData || {}

  const metadataOverrides = {
    title: metadata?.title || post.metadata.title,
    description: metadata?.description || post.metadata.description,
    openGraphImage: metadata?.image?.fixed?.src || post.metadata.openGraphImage
  }

  return (
    <Page
      {...props}
      metadata={{ ...post.metadata, ...metadataOverrides }}
      pageOptions={{
        headerVariant: "cream",
        breadcrumbs: [
          ...PageBreadcrumbs.Post,
          {
            label: post.title,
            link: `${props.uri}`
          }
        ]
      }}
    >
      <PostDetailsPage post={post} />
    </Page>
  )
}

export default PostTemplate

export const query = graphql`
  query PostPage($id: String) {
    contentfulPost(id: { eq: $id }) {
      ...PostDetails
    }
  }
`
